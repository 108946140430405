import { ANALYTICS_EVENT, useAnalytics } from '@/utils/analytics';
import { signOut } from 'firebase/auth';
import { useAuth } from './useAuth';

export function useLogout() {
  const { auth } = useAuth();
  const analytics = useAnalytics();

  return () => {
    signOut(auth).then(() => {
      analytics.track(ANALYTICS_EVENT.LOGOUT);
      localStorage.clear();
      window.location.reload();
    });
  };
}
