import { applyActionCode, AuthError } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';

export function useVerifyEmail(code?: string) {
  const { auth } = useAuth();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (code) {
      request(code);
    }
  }, [code]);

  const request = async (code: string) => {
    setLoading(true);
    setError(undefined);
    try {
      await applyActionCode(auth, code);
      setSuccess(true);
    } catch (error) {
      setError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  return { loading, success, error };
}
