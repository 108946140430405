import { AuthError, sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { useAuth } from './useAuth';

export function useSendPasswordResetEmail() {
  const { auth } = useAuth();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const request = async (email: string) => {
    setLoading(true);
    setError(undefined);

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (error) {
      setError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  return { request, loading, error, success };
}
