import { AuthError, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useAuth } from './useAuth';

export function useLogin() {
  const { auth } = useAuth();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState(false);

  const request = async (email: string, password: string) => {
    setLoading(true);
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      if (userCredentials.user) {
        return userCredentials.user;
      }
    } catch (error) {
      setError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  return {
    request,
    error,
    loading,
  };
}
