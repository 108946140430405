import {
  AuthError,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { useState } from 'react';
import { useAuth } from './useAuth';

export function useCreateAccount() {
  const { auth } = useAuth();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState(false);

  const request = async ({
    email,
    password,
    displayName,
    onSuccess,
  }: {
    email: string;
    password: string;
    displayName: string;
    onSuccess?: () => void;
  }) => {
    setLoading(true);
    setError(undefined);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      if (userCredential.user) {
        await updateProfile(userCredential.user, { displayName });
      }

      onSuccess && onSuccess();

      return userCredential.user;
    } catch (error) {
      setError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  return {
    request,
    error,
    loading,
  };
}
